import * as React from 'react';
import {useState} from 'react';
import {Title, useDataProvider} from 'react-admin';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {makeStyles} from '@material-ui/core/styles';
import SubmitButton from '../Components/SubmitButton';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
  button: {
    marginTop: '10px',
  },
  fileName: {
    verticalAlign: 'baseline',
    marginLeft: '10px'
  }
}));

const Prices = props => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [file, setFile] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const sendFile = (e) => {
    e.preventDefault();
    if (!file) {
      alert('Файл не выбран');
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    //console.log(formData, file)
    dataProvider.sendFormDataRequest('prices', formData).then(data => {
      setIsLoading(false);
      setFile('');
      alert('Файл успешно загружен');
    }).catch(error => {
      setIsLoading(false);
      //console.log(error)
      //debugger;
      alert('Не удалось загрузить файл');
    });
  };

  const handleFile = ({target}) => {
    setFile(target.files[0]);
  };

  return (
    <Card>
      <Title title="Загрузка файла с ценами" />
      <CardContent>
        <div>Загрузите файл с ценами</div>

        <form onSubmit={sendFile}>
          <div>
            <input
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              className={classes.input}
              id="contained-button-file"
              type="file"
              onChange={handleFile}
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="contained"
                color="default"
                component="span"
                className={classes.button}
                startIcon={<CloudUploadIcon />}
              >
                Выберите файл
              </Button>
            </label>
            <span className={classes.fileName}>{file.name || ''}</span>
          </div>

          <SubmitButton
            variant='contained'
            color='primary'
            isLoading={isLoading}
            text='Отправить'
          />
        </form>
      </CardContent>
    </Card>
  );
};

export default Prices;