import React, {useEffect, useState} from "react"
import {useDataProvider, useNotify} from "react-admin";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import "./Individual/Components/style.css"

const PushesByCityHistory = props => {
    const {cityKey} = props
    const dataProvider = useDataProvider()
    const notify = useNotify()
    const [history, setHistory] = useState([])

    useEffect(() => {
        dataProvider.getList(`push/history/cities?city_key=${cityKey}`, {})
            .then(response => {
                setHistory(response.data)
            })
            .catch(reason => {
                notify("Ошибка загрзуки истории", "warning")
            })
    }, [])

    return (
        <div className="card">
            <DataTable paginator rows={10} className="p-datatable-gridlines" dataKey="id" value={history}>
                <Column key="id" field="title" filter header="Заголовок" filterMatchMode="contains"
                        filterPlaceholder="Поиск по заголовку"/>
                <Column key="id" field="body" filter header="Сообщение" filterMatchMode="contains"
                        filterPlaceholder="Поиск по сообщению"/>
                <Column key="id" field="sent_at" filter header="Дата отправки" filterMatchMode="contains"
                        filterPlaceholder="Поиск по дате отправки"/>
            </DataTable>
        </div>
    )
}

export default PushesByCityHistory