import React, {useState} from "react"
import {Card, CircularProgress, TextField} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDataProvider, useNotify} from "react-admin";

const useStyles = makeStyles(theme => ({
    title: {
        fontSize: 20
    },
    textField: {
        marginLeft: 10
    },
    textArea: {
        marginTop: 10
    },
    submitButton: {
        marginLeft: 10,
        marginTop: 10
    }
}))

const PushForm = props => {
    const classes = useStyles()
    const {selectedUsers} = props
    const [isLoading, setIsLoading] = useState(false)
    const [formData, setFormData] = useState({})
    const dataProvider = useDataProvider()
    const notify = useNotify()
    const formSubmit = event => {
        event.preventDefault()
        if (selectedUsers.length === 0) {
            notify("Вы не выбрали ни одного пользователя!", "warning")
            return
        }
        let sendData = formData
        let endedAt =  new Date(sendData.endedAt).setHours(0,0,0,0)
        let currentDate = new Date().setHours(0,0,0,0)
        if (endedAt < currentDate) {
            notify("Вы не можете отправить уведомление на прошедшую дату", "warning")
            return
        }
        sendData.users = selectedUsers.map(e => e.id)
        setIsLoading(true)
        dataProvider.sendRequest(`push/individual/${props.fileId}/send`, sendData)
            .then(response => {
                notify("Отправка push'a успешно завершена!")
                setIsLoading(false)
            })
            .catch(reason => {
                notify("Ошибка отправки push'a", "warning")
                setIsLoading(false)
            })

    }
    const inputChange = e => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }
    if (isLoading) return (
        <Card>
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Отправка пуша
                </Typography>
                <CircularProgress/>
            </CardContent>
        </Card>
    )
    return (
        <Card>
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Отправка пуша
                </Typography>
                <form className={classes.root} onSubmit={formSubmit}>

                    <TextField name="title" label="Заголовок" required
                               onChange={inputChange}/>
                    <TextField
                        label="Срок действия"
                        type="date"
                        className={classes.textField}
                        onChange={inputChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="endedAt"
                    />
                    <Button type="submit" className={classes.submitButton} variant="contained" color={"primary"}
                    > Отправить
                    </Button>
                    <div>
                        <TextField
                            className={classes.textArea}
                            label="Текст сообщения"
                            multiline
                            variant="outlined"
                            required
                            name="body"
                            onChange={inputChange}
                        />
                    </div>
                </form>

            </CardContent>
        </Card>
    )
}

export default PushForm