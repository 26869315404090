import * as React from 'react';
import { List, Datagrid, TextField, BooleanField } from 'react-admin';

const SettingsList = props => (
  <List {...props}
        pagination={null}
        title='Настройки пушей'
        exporter={false}
        bulkActionButtons={false}
  >
    <Datagrid rowClick="edit">
      <TextField label="Название" source="name" />
      <TextField label="Описание" source="description" />
      <BooleanField label="Активность" source="is_active" />
    </Datagrid>
  </List>
);

export default SettingsList;