import {fetchUtils} from "react-admin";

export default ((apiUrl, hashStr, authUrl, httpClient = fetchUtils.fetchJson) => (
    {
        login: ({username, password}) => {
            return httpClient(authUrl, {
                method: 'POST',
                body: JSON.stringify({'phone': username, password, 'city_key': 'krnd'}),
            })
                .then(response => {
                    if (response.status < 200 || response.status >= 300) throw new Error(response.statusText)
                    return response.json
                })
                .then(({token}) => {
                    localStorage.setItem('token', token);
                })
        },
        logout: () => {
            localStorage.removeItem('token');
            return Promise.resolve();
        },
        checkError: ({status}) => {
            if (status === 401 || status === 403) {
                localStorage.removeItem('token');
                return Promise.reject();
            }
            return Promise.resolve();
        },
        checkAuth: () => {
            return localStorage.getItem('token')
                ? Promise.resolve()
                : Promise.reject();
        },
        getPermissions: () => Promise.resolve(),
    }
));