import * as React from 'react';
import Button from '@material-ui/core/Button';
import {green} from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: 'absolute',
  },
  button: {
    marginTop: '10px'
  }
}));

const SubmitButton = props => {
  const { variant, color, isLoading, text } = props;
  const classes = useStyles();

  return (
    <Button
      type='submit'
      variant={variant}
      color={color}
      disabled={isLoading}
      className={classes.button}
    >
      {text}
      {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </Button>
  );
};

export default SubmitButton;