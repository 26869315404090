import React from "react"
import {DialogActions, DialogContent, DialogContentText} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {syncDoctor} from "./DoctorSyncDialogForm";
import {useNotify, useRedirect} from "react-admin";

const DoctorSyncDialogFound = props => {
    const {doctor, handleClose, dataProvider, code1C, city_key} = props
    const notify = useNotify()
    const redirect = useRedirect()
    const handleConfirmed = () => {
        syncDoctor(dataProvider,props.basePath,notify,redirect,handleClose,code1C,
            props.record,props.id,{code:code1C, is_sync_with_1c: true}, city_key)
        dataProvider.getReceptionsSchedule("schedules/current?city_key=" + city_key)
    }
    return (
        <>
            <DialogContent>
                <DialogContentText>
                    Данный код принадлежит врачу {doctor.full_name}! Вы действительно хотите продолжить синхронизацию?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    Закрыть
                </Button>
                <Button onClick={handleConfirmed} color="primary">
                    Подтвердить
                </Button>
            </DialogActions>
        </>
    )
}

export default DoctorSyncDialogFound