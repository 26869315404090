import * as React from 'react'
import {Edit, TextField, TextInput, SimpleForm, DeleteButton, useEditController, SaveButton} from "react-admin"
import Toolbar from "../../Layout/Toolbar";
import ScheduleDeleteButton from "../../Components/ScheduleDeleteButton";


const Title = ({record}) => {
    return <span>Расписание {record ? `${record.address}` : ''}</span>;
};

const ScheduleEdit = props => {
    const {basePath} = useEditController(props)
    return (
        <Edit {...props} title={<Title/>}>
            <SimpleForm redirect={basePath.replace(":city_key",window.location.href.split("#")[1].split("/")[3])} toolbar={<Toolbar/>}>
                <TextField disabled label={"Адрес"} source="address"/>
                <TextInput label="Расписание" source="schedule"/>
                <ScheduleDeleteButton props={props}/>
            </SimpleForm>
        </Edit>
    )
}

export default ScheduleEdit