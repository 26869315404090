import React, {useEffect, useState} from "react"
import {useDataProvider, useNotify} from "react-admin";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import "./Components/style.css"


const PushHistory = props => {
    const {fileId} = props
    const dataProvider = useDataProvider()
    const notify = useNotify()
    const [history, setHistory] = useState([])

    useEffect(() => {
        dataProvider.getList(`push/individual/history/${fileId}`, {})
            .then(response => {
                const usersPushData = response.data.map(value => value.users.map(user => ({
                    title: value.title,
                    body: value.body, sentAt: value.sent_at, endedAt: value.ended_at ? value.ended_at:"Отсутствует",
                    fullName: `${user.last_name} ${user.first_name} ${user.patronymic_name}`,
                    cityName: user.city_name, phone: user.phone
                }))).reduce((a, b) => a.concat(b), [])
                setHistory(usersPushData)
            })
            .catch(reason => {
                if (reason.status === 404) {
                    notify("История индивидуальных уведомлений пуста. Не было отправлено ни одного сообщения", "info")
                } else {
                    notify("Ошибка загрузки истории индивидуальных уведомлений", "warning")
                }
            })
    }, [])

    return (
        <div className="card">
            <DataTable paginator rows={10} className="p-datatable-gridlines" dataKey="id" value={history}>
                <Column key="id" field="fullName" filter header="ФИО" filterMatchMode="contains"
                        filterPlaceholder="Поиск по ФИО"/>
                <Column key="id" field="phone" filter header="Телефон" filterMatchMode="contains"
                        filterPlaceholder="Поиск по телефону"/>
                <Column columnKey="id" field="cityName" filter header="Город" filterMatchMode="contains"
                        filterPlaceholder="Поиск по городу"/>
                <Column key="id" field="title" filter header="Заголовок" filterMatchMode="contains"
                        filterPlaceholder="Поиск по заголовку"/>
                <Column key="id" field="body" filter header="Сообщение" filterMatchMode="contains"
                        filterPlaceholder="Поиск по сообщению"/>
                <Column key="id" field="sentAt" filter header="Дата отправки" filterMatchMode="contains"
                        filterPlaceholder="Поиск по дате отправки"/>
                <Column key="id" field="endedAt" filter header="Срок действия" filterMatchMode="contains"
                        filterPlaceholder="Поиск по сроку действия"/>
            </DataTable>
        </div>
    )
}

export default PushHistory