import React from 'react';
import { Layout, Sidebar } from 'react-admin';
import Menu from './Menu';

const MySidebar = props => (
  <div style={{ maxWidth:'20%',order:-1 }}>
    <Sidebar {...props}/>
  </div>
);
const MainLayout = (props) => <Layout {...props} menu={Menu} sidebar={MySidebar}/>;

export default MainLayout;