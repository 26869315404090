import * as React from 'react';
import {List, Datagrid, TextField, BooleanField} from 'react-admin';
import {Alert, AlertTitle} from "@material-ui/lab";


const DoctorList = props => {

    return (
        <List {...props}
              pagination={null}
              exporter={false}
              title='Врачи'
              bulkActionButtons={false}
              filter={{city_key: props.match.params.city_key}}
              actions={false}
        >
            <>
                <Alert severity="warning">
                    <AlertTitle><strong>Внимание</strong></AlertTitle>
                    Если Вы обнаружили дублирование врачей в списке, удалите дублирующую запись с пометкой "1С", затем
                    заполните данные о враче с пометкой "Сайт"
                </Alert>
                <Datagrid
                    rowClick={(id, basePath, record) => `${(basePath.split(':'))[0]}${props.match.params.city_key}/${id}`}
                    rowStyle={props.options.rowStyle}>
                    <TextField source="id" sortable={false}/>
                    <TextField label="Полное имя (на сайте)" source="full_name" sortable={false}/>
                    <TextField label="Имя" source="first_name" sortable={false}/>
                    <TextField label="Фамилия" source="last_name" sortable={false}/>
                    <TextField label="Отчество" source="patronymic_name" sortable={false}/>
                    <TextField label="Специализация" source="specialization" sortable={false}/>
                    <BooleanField label="Есть на сайте" source="is_has_description" sortable={false}/>
                    <BooleanField label="Синхронизация 1С" source="is_sync_with_1c" sortable={false}/>
                    <TextField label="Код 1С" source="code" sortable={false}/>
                </Datagrid>
            </>
        </List>
    );
};


export default DoctorList;
