import * as React from "react"
import {Create, SimpleForm, TextInput, SelectInput, useDataProvider, useCreateController} from "react-admin";
import {useEffect, useState} from "react";
import Toolbar from "../../Layout/Toolbar";

const ScheduleCreate = props => {
    const dataProvider = useDataProvider()
    const {basePath} = useCreateController(props)
    const [addresses, setAddresses] = useState([])
    const {city_key} = props.match.params
    useEffect(() => {
        dataProvider.getList('clinics', {filter: {city_key: city_key}})
            .then(clinicAddresses => {
                const addresses = clinicAddresses.data.map(clinicAddress => {
                    return {id: clinicAddress.id, name: clinicAddress.title}
                });
                setAddresses(addresses)
            })
    },[city_key])
    if (!city_key) return
    return (
        <Create {...props} title={"Добавить расписание"}>
            <SimpleForm redirect={basePath.replace(":city_key", city_key)} toolbar={<Toolbar />}>
                <SelectInput label="Адрес" source="addressId" choices={addresses}/>
                <TextInput label="Расписание" source="schedule"/>
            </SimpleForm>
        </Create>
    )
}

export default ScheduleCreate
