import * as React from 'react';
import { List, Datagrid, TextField} from 'react-admin';
import ScheduleEmpty from "./ScheduleEmpty";
import ScheduleActions from "./ScheduleActions";

const ScheduleList = props => {
    const {city_key} = props.match.params
    return(
        <List {...props}
              pagination={null}
              exporter={false}
              title='Расписание'
              bulkActionButtons={false}
              actions={<ScheduleActions />}
              empty={<ScheduleEmpty city_key={city_key}/>}
              filter={{city_key: city_key}}
        >
            <Datagrid rowClick={(id, basePath, record) => `${basePath.replace(":city_key", city_key)}/${id}`}>
                <TextField label="Адрес" source="address" sortable={false} />
                <TextField label="Расписание" source="schedule" sortable={false} />
            </Datagrid>
        </List>
    )
}

export default ScheduleList