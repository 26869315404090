import React, {useState} from "react"
import FileLoadForm from "./Components/FileLoadForm";
import {Grid} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FileList from "./Components/FileList";

const useStyles = makeStyles(theme => ({
    grid: {
        padding: "10px"
    }
}))

const PushFileList = props => {
    const classes = useStyles()
    const [forceUpdate, setForceUpdate] = useState(false)
    return (
        <Grid className={classes.grid}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
              item
        >
            <FileLoadForm forceUpdate={forceUpdate} setForceUpdate={setForceUpdate}/>
            <FileList forceUpdate={forceUpdate} setForceUpdate={setForceUpdate}/>
        </Grid>
    )
}

export default PushFileList