import * as React from "react"
import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {CreateButton} from "react-admin"

const BannersEmpty = props => {
    return(
        <Box textAlign="center" m={1}>
            <Typography component={'span'} variant="h5">
                <p>Баннеры отсутствуют...</p>
            </Typography>
            <CreateButton basePath={window.location.href.split("#")[1]}/>
        </Box>
    )
}

export default BannersEmpty