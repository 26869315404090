import {fetchUtils} from 'react-admin';
import {getCleanPath} from './utils';

export default (function (apiUrl, httpClient = fetchUtils.fetchJson) {
    return ({
        getList: function (resource, params) {
            const url = apiUrl + "/" + getCleanPath(resource, '/') + (params.filter && params.filter.city_key ? `?city_key=${encodeURIComponent(params.filter.city_key)}` : '');

            return httpClient(url).then(data => {
                return {
                    data: data.json.content,
                    total: data.json.totalElements,
                };
            });
        },
        getOne: function (resource, params) {
            return httpClient(apiUrl + "/" + getCleanPath(resource, '/') + "/" + params.id)
                .then(data => {
                    return ({
                        data: data.json,
                    });
                });
        },
        getMany: function (resource, params) {
            const query = {
                filter: JSON.stringify({id: params.ids}),
            };
            const url = apiUrl + "/" + resource + query;
            return httpClient(url).then(data => {
                return ({data: data.json});
            });
        },
        getManyReference: function (resource, params) {
            const query = {};
            const url = apiUrl + "/" + getCleanPath(resource, '/');// + "?" + stringify(query);
            return httpClient(url).then(data => {
                return {
                    data: data.json.content,
                    total: data.json.totalElements,
                };
            });
        },
        update: function (resource, params) {
            if(!params.data?.image_url) params.data.image_url = ""
            if (params.data.image_url.rawFile) {
                return this.convertFileToBase64(params.data.image_url.rawFile).then(data => {
                    params.data.image = data;

                    return httpClient(apiUrl + "/" + getCleanPath(resource, '/') + "/" + params.id, {
                        method: 'PATCH',
                        body: JSON.stringify(params.data),
                    }).then(data => {
                        return ({data: data.json});
                    });
                });
            } else {
                return httpClient(apiUrl + "/" + getCleanPath(resource, '/') + "/" + params.id, {
                    method: 'PATCH',
                    body: JSON.stringify(params.data),
                }).then(data => {
                    return ({data: data.json});
                });
            }
        },
        // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
        updateMany: function (resource, params) {
            return Promise.all(params.ids.map(function (id) {
                return httpClient(apiUrl + "/" + getCleanPath(resource, '/') + "/" + id, {
                    method: 'PUT',
                    body: JSON.stringify(params.data),
                });
            })).then(responses => {
                return ({
                    data: responses.map(data => {
                        return data.json.id;
                    })
                });
            });
        },
        sendPushByCity: function (params) {
            return httpClient(apiUrl + "/push/city", {
                method: 'POST',
                body: JSON.stringify(params),
            }).then(data => {
                return ({data: data.json});
            }).catch(error => {
                return error;
            });
        },

        sendRequest: function (path, params) {
            return httpClient(`${apiUrl}/${path}`, {
                method: 'POST',
                body: JSON.stringify(params),
            }).then(data => {
                return ({data: data.json});
            }).catch(error => {
                return error;
            });
        },

        getRegistrationCount: function (path, params) {
            return httpClient(`${apiUrl}/${path}`, {
                method: 'GET'
            }).then(data => {
                return ({data: data.json});
            }).catch(error => {
                return error;
            });
        },

        getStatistics: function (path, params) {
            return httpClient(`${apiUrl}/${path}`, {
                method: 'GET'
            }).then(data => {
                return ({data: data.json});
            }).catch(error => {
                return error;
            });
        },

        getReceptionsSchedule: function (path, params) {
            return httpClient(`${apiUrl}/${path}`, {
                method: 'GET'
            }).then(data => {
                return ({data: data.json});
            }).catch(error => {
                return error;
            });
        },

        sendFormDataRequest: function (path, formData) {
            const headers = new Headers({'enctype': 'multipart/form-data'});
            return httpClient(`${apiUrl}/${path}`, {
                method: 'POST',
                body: formData,
            }, {headers: headers}).then(data => {
                //debugger;
                return ({data: data.json});
            }).catch(error => {
                return error;
            });
        },

        delete: function (resource, params) {
            return httpClient(apiUrl + "/" + getCleanPath(resource, '/') + "/" + params.id, {
                method: 'DELETE'
            }).then(data => {
                return ({
                    data: data.json,
                    status: data.status
                });
            });
        },

        create: function (resource, params) {
            return httpClient(apiUrl + "/" + getCleanPath(resource, '/') + "/create", {
                method: 'POST',
                body: JSON.stringify(params.data),
            }).then(data => {
                return ({data: data.json, status: data.status});
            })
        },
        // // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
        // deleteMany: function (resource, params) {
        //   return Promise.all(params.ids.map(function (id) {
        //     return httpClient(apiUrl + "/" + resource + "/" + id, {
        //       method: 'DELETE',
        //     });
        //   })).then(function (responses) { return ({ data: responses.map(function (_a) {
        //       var json = _a.json;
        //       return json.id;
        //     }) }); });
        // },

        convertFileToBase64: function (file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result);
                reader.onerror = reject;

                reader.readAsDataURL(file);
            });
        }
    });
});