export const postRowStyle = (record, index) => {
  let style = {}
  if(!record.is_sync_with_1c){
    style = {
      ...style,
      backgroundColor: 'rgba(255, 0, 0, 0.4)'
    }
  }
  if (!record.is_active) {
    style = {
      ...style,
      opacity: '0.5',
      fontStyle: 'italic',
      backgroundColor: 'none'
    }
  }
  return style
};

export const getCleanPath = (path, separator) => {
  return path.includes(':city_key') ? (path.replace("/:city_key","")) : path;
}