import * as React from 'react';
import { Edit, SimpleForm, TextField } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import Toolbar from '../../Layout/Toolbar';

const Title = ({ record }) => {
  return <span>Услуга {record ? `"${record.title}"` : ''}</span>;
};

const ServiceEdit = props => (
  <Edit {...props} title={<Title />}>
    <SimpleForm redirect={false} toolbar={<Toolbar />}>
      <TextField source="id" />
      <TextField label="Название" source="title" />
      <RichTextInput label="Описание" source="description" />
    </SimpleForm>
  </Edit>
);

export default ServiceEdit;