import React from "react"
import {DialogActions, DialogContent, DialogContentText, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useNotify, useRedirect} from "react-admin";

export const syncDoctor = (dataProvider, basePath, notify, redirect, handleClose, code1C, previousData, id, data, city_key) => {
    dataProvider.update("doctors", {
        id: id,
        data: data,
        previousData: previousData
    })
        .then(response => {
            notify("Синхронизация прошла успешно!", 'info')
            handleClose()
            redirect(basePath.replace(":city_key", city_key))
        })
        .catch(error => {
            notify("Синхронизация не удалась!", 'error')
            handleClose()
            redirect(basePath.replace(":city_key", city_key))
        })
}

const DoctorSyncDialogForm = props => {
    const {dataProvider, handleClose, setLoading, setFoundDoctor, city_key, setCode1C, code1C, setError, id} = props
    const handleConfirmed = async (event) => {
        event.preventDefault()
        if(!code1C.trim()) return
        setLoading(true)
        const response = await checkCode()
        if (response?.data) {
            setFoundDoctor(response.data)
        }
        setLoading(false)
    }
    const checkCode = async () => {
        return dataProvider.getOne("doctors/check_code", {id: `?code=${code1C}&city_key=${city_key}&doctor_id=${id}`})
            .then(response => {
                return response
            })
            .catch(error => {
                setError(true)
                return null
            })
    }
    return (
        <DialogContent>
            <DialogContentText>
                Для синхронизации введите код 1С
            </DialogContentText>
            <form onSubmit={handleConfirmed}>
                <TextField required={true} variant={"standard"} label={"Код 1С"}
                           onChange={(event) => setCode1C(event.target.value)}/>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Закрыть
                    </Button>
                    <Button type={"submit"} color="primary">
                        Подтвердить
                    </Button>
                </DialogActions>
            </form>
        </DialogContent>
    )
}

export default DoctorSyncDialogForm