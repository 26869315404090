import makeStyles from "@material-ui/core/styles/makeStyles";
import {SortableContainer, SortableElement} from "react-sortable-hoc";
import {useRedirect} from "react-admin";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import React, {Component} from "react";
import arrayMove from "array-move";

const useStyle = makeStyles((theme) => ({
    hover: {
        "&:hover": {
            cursor: "Pointer",
        },
    },
    nonActiveStyle: {
        opacity: '0.5',
        fontStyle: 'italic',
        backgroundColor: 'none'
    }
}));

const SortableItem = SortableElement(({value, city_key}) => {
    const classes = useStyle()
    const redirect = useRedirect()
    return (
        <TableRow onClick={() => redirect(`/list_of_banners/${city_key}/${value.id}`)}
                  className={`${classes.hover} ${!value.is_active && classes.nonActiveStyle}`} hover>
            <TableCell>{value.id}</TableCell>
            <TableCell>{value.title_mp}</TableCell>
        </TableRow>
    )
});

const SortableList = SortableContainer(({items, city_key}) => {
    return (
        <TableContainer>
            <Table size="medium" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell align={"left"}>Заголовок</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((value, index) => (
                        <SortableItem key={index} index={index} value={value} city_key={city_key}/>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
});

class SortableComponent extends Component {

    state = {
        items: []
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        this.setState(({items}) => ({
            items: arrayMove(items, oldIndex, newIndex),
        }));
        const requestBody = this.state.items.map((value, index) => ({id: value.id, index: index}))
        this.props.dataProvider.sendRequest('indexes', requestBody)
            .then(response=>{
                //Not implemented
            }).catch(reason => {
            this.props.notify("Произошла ошибка обновления порядка!", "warning")
        })
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {data} = this.props
        if (data !== prevProps.data) {
            if (data !== null) {
                this.setState({
                    items: Object.keys(data)
                    .map((key) => data[key])
                    .filter(value => value.city_key === this.props.city_key)
                    .sort((a, b) => (a.index > b.index) ? 1 : -1)
                })
            }
        }
    }

    render() {
        const {items} = this.state
        return (items && <SortableList items={this.state.items} onSortEnd={this.onSortEnd} pressDelay={150}
                                       city_key={this.props.city_key}/>)


    }
}

export default SortableComponent