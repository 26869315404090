import React, {createRef, useState} from "react"
import Button from "@material-ui/core/Button";
import {Card, CircularProgress, Grid} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDataProvider, useNotify} from "react-admin";

const useStyles = makeStyles(theme => ({
    title: {
        fontSize: 20
    },
    uploadButton: {
        marginTop: 10,
    }
}))


const FileLoadForm = props => {
    const classes = useStyles()
    const dataProvider = useDataProvider()
    const notify = useNotify()
    const {forceUpdate, setForceUpdate} = props
    const [file, setFile] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const onChange = ({target}) => {
        setFile(target.files[0])
    }
    const uploadFile = (event) => {
        if (!file) return
        const formData = new FormData()
        formData.append("file", file)
        setIsLoading(true)
        dataProvider.sendFormDataRequest('push/individual/upload', formData)
            .then(data => {
                setForceUpdate(!forceUpdate)
                notify("Файл успешно загружен")
                setIsLoading(false)
            })
            .catch(reason => {
                notify("Ошибка загрузки. Проверьте формат файла.", "warning")
                setIsLoading(false)
            })
    }

    if (isLoading) return (
        <Card>
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Загрузка файла
                </Typography>
                <CircularProgress/>
            </CardContent>
        </Card>
    )
    return (
        <Card>
            <CardContent>
                <Grid className={classes.grid}
                      item
                      direction="column"
                      justify="flex-start"
                      alignItems="flex-start"
                      xs={8}
                >
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Загрузка файла
                    </Typography>
                    <input
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        style={{display: 'none'}}
                        id="raised-button-file"
                        type="file"
                        onChange={onChange}
                    />
                    <label htmlFor="raised-button-file">
                        <Button variant="contained" component="span">
                            {file?.name ? file?.name : "Выберите файл..."}
                        </Button>
                    </label>
                    <Button onClick={uploadFile} className={classes.uploadButton} variant="contained" color={"primary"}
                            component="span">
                        Загрузить
                    </Button>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default FileLoadForm