import * as React from 'react';
import { FC, useState, useEffect } from 'react';
import update from 'immutability-helper';
import { useSelector } from 'react-redux';
import { useMediaQuery, Theme } from '@material-ui/core';
import { DashboardMenuItem, MenuItemLink, useDataProvider } from 'react-admin';

import SubMenu from './SubMenu';

interface Props {
  dense: boolean;
  logout: () => void;
  onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
  const dataProvider = useDataProvider();

  const [menu, setMenu] = useState({});

  useEffect(() => {
    const menu = {};
    dataProvider.getList('cities', {}).then(data => {
        if(data) {
            data.data.forEach(city => {
                menu[city.key] = {
                    key: city.key,
                    name: city.name,
                    isOpen: false
                };
            });
        }
    });
    setMenu(menu);
  }, []);

  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );

  const handleToggle = (key) => {
    setMenu(update(menu, {[key]: {$toggle: ['isOpen']}}));
  };

  const isOpen = useSelector(state => state.admin.ui.sidebarOpen);
  return (
    <div>
      <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={isOpen} primaryText="Skinerica admin" />
      {menu && Object.values(menu).map(city => {
        return (
          <SubMenu
            key={city.key}
            handleToggle={() => handleToggle(city.key)}
            isOpen={city.isOpen}
            sidebarIsOpen={isOpen}
            name={city.name}
            dense={dense}
          >
            <MenuItemLink
              key={`${city.key}.banners`}
              to={`/list_of_banners/${city.key}`}
              primaryText={'Баннеры'}
              onClick={onMenuClick}
              sidebarIsOpen={isOpen}
              dense={dense}
            />
            <MenuItemLink
              key={`${city.key}.doctors`}
              to={`/doctors/${city.key}`}
              primaryText={'Врачи'}
              onClick={onMenuClick}
              sidebarIsOpen={isOpen}
              dense={dense}
            />
            <MenuItemLink
              key={`${city.key}.categories`}
              to={`/all_services/${city.key}`}
              primaryText={'Услуги'}
              onClick={onMenuClick}
              sidebarIsOpen={isOpen}
              dense={dense}
            />
            <MenuItemLink
                to={`/clinics/schedule/${city.key}`}
                primaryText={'Расписание'}
                onClick={onMenuClick}
                sidebarIsOpen={isOpen}
                dense={dense}
                />
            <MenuItemLink
              key={`${city.key}.push`}
              to={`/pushes/${city.key}`}
              primaryText='Отправить пуш'
              onClick={onMenuClick}
              sidebarIsOpen={isOpen}
              dense={dense}
            />
          </SubMenu>
        );
      })}
      <MenuItemLink
        key='prices'
        to={'/prices'}
        primaryText='Цены'
        onClick={onMenuClick}
        sidebarIsOpen={isOpen}
        dense={dense}
      />
      <MenuItemLink
        key={`statistics`}
        to={`/statistics`}
        primaryText={'Статистика'}
        onClick={onMenuClick}
        sidebarIsOpen={isOpen}
        dense={dense}
      />
      <MenuItemLink
        key='individual_pushes'
        to={'/individual-pushes'}
        primaryText='Индив. предложение'
        onClick={onMenuClick}
        sidebarIsOpen={isOpen}
        dense={dense}
      />
      <MenuItemLink
        key='push_settings'
        to={'/push/settings'}
        primaryText='Настройки пушей'
        onClick={onMenuClick}
        sidebarIsOpen={isOpen}
        dense={dense}
      />
      {isXSmall && logout}
    </div>
  );
};

export default Menu;