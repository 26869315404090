import * as React from 'react';
import {Edit, SimpleForm, TextField, TextInput, required, BooleanField} from 'react-admin';
import Toolbar from '../../Layout/Toolbar';
import DoctorDeleteButton from '../../Components/DoctorDeleteButton'
import Doctor1CSyncButton from "../../Components/Doctor1CSyncButton";


const Title = ({record}) => {
    return <span>Врач {record ? `"${record.last_name} ${record.first_name} ${record.patronymic_name}"` : ''}</span>;
};


const DoctorEdit = (props) => {

    return (
        <Edit {...props} title={<Title/>}>
            <SimpleForm redirect={false} toolbar={<Toolbar/>}>
                <TextField label="Id" source="id"/>
                <TextInput disabled label="Полное имя (на сайте)" source="full_name"/>
                <TextInput label="Имя" source="first_name" validate={required()}/>
                <TextInput label="Фамилия" source="last_name" validate={required()}/>
                <TextInput label="Отчество" source="patronymic_name" validate={required()}/>
                <TextField label="Специализация" source="specialization"/>
                <BooleanField label="Врач был создан на сайте" source="is_has_description"/>
                <Doctor1CSyncButton props={props}/>
                <DoctorDeleteButton props={props}/>
            </SimpleForm>
        </Edit>
    );
};

export default DoctorEdit;
