import React, {useEffect, useState} from "react"
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {
    Avatar,
    Card,
    Grid,
    IconButton,
    ListItem, ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DescriptionIcon from '@material-ui/icons/Description';
import {useDataProvider, useNotify, useRedirect} from "react-admin";
import DeleteIcon from "@material-ui/icons/Delete";
import List from "@material-ui/core/List";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
    fileList: {
        marginTop: 10,
        width: "100%",
        maxWidth: 340,
        backgroundColor: theme.palette.background.paper
    },
    title: {
        fontSize: 20
    },
    listItem: {
        paddingLeft: 0
    },
    deleteButton: {
        color: "red"
    },
    downloadButton: {
        color: "blue"
    },
    secondaryAction:{
        position:"relative",
        transform:"none",
        paddingLeft:15
    }
}))

const FileList = props => {
    const dataProvider = useDataProvider()
    const notify = useNotify()
    const classes = useStyles()
    const redirect = useRedirect()
    const {forceUpdate, setForceUpdate} = props
    const [files, setFiles] = useState([])
    const removeItem = (fileData) => {
        if (!window.confirm(`Вы действительно хотите удалить ${fileData.name}?`)) return
        dataProvider.delete('push/individual', {id: fileData.id})
            .then(response => {
                notify("Файл успешно удалён")
                setForceUpdate(!forceUpdate)
            })
            .catch(reason => {
                notify("Ошибка удаления файла", "warning")
            })
    }
    useEffect(() => {
        dataProvider.getList('push/individual', {})
            .then(response => {
                setFiles(response.data)
            })
            .catch(reason => {
                notify("Ошибка загрузки списка файлов", "warning")
            })
    }, [forceUpdate])
    const items = files?.map((fileData) =>
        <ListItem key={fileData.id} alignItems="flex-start" dense className={classes.listItem}>
            <ListItemAvatar>
                <Avatar>
                    <DescriptionIcon/>
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={fileData.name}
                secondary={fileData.created_at}
            />
            <ListItemSecondaryAction className={classes.secondaryAction}>
                <Button  color={"primary"}
                         component="span" onClick={() => redirect(`individual-pushes/${fileData.id}`)}>
                    Открыть список
                </Button>
                <a href={fileData.file_url} download>
                    <IconButton className={classes.downloadButton} edge="end"
                                aria-label="Скачать">
                        <ArrowDownwardIcon/>
                    </IconButton>
                </a>
                <IconButton edge="end" aria-label="История" onClick={() => redirect(`individual-pushes/${fileData.id}/history`)}>
                    <AccessTimeIcon/>
                </IconButton>
                <IconButton onClick={() => removeItem(fileData)} className={classes.deleteButton} edge="end"
                            aria-label="delete">
                    <DeleteIcon/>
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    )

    return (
        <Card className={classes.fileList}>
            <CardContent>
                <Grid>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Список файлов
                    </Typography>
                    <List>
                        {items.length > 0 ? items : "Файлы отсутствуют..."}
                    </List>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default FileList