import * as React from 'react';
import {useState, useEffect} from 'react';
import {
    Edit,
    SimpleForm,
    TextField,
    TextInput,
    SelectInput,
    ImageInput,
    BooleanInput,
    FormDataConsumer,
    required,
    useDataProvider, SaveButton, DeleteButton, Toolbar, useRedirect, useNotify
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    input: {
        width: '40%'
    },
    deleteButton: {
        backgroundColor: "red",
        color: "white",
        marginLeft: "5px"
    }
}));

const Title = ({record}) => {
    return <span>Баннер {record ? `"${record.title_mp || ''}"` : ''}</span>;
};

const BannerToolbar = props => {
    const classes = useStyles()
    const dataProvider = useDataProvider()
    const redirect = useRedirect()
    const notify = useNotify()

    const deleteBanner = () => {
        dataProvider.delete("list_of_banners", {id:props.record.id})
            .then(()=>{
                redirect(`/list_of_banners/${props.city_key}`)
                window.location.reload()
            })
            .catch(()=>{
                notify("Ошибка удаления баннера")
            })
    }

    return (
        <Toolbar {...props}>
            <SaveButton label='Сохранить'/>
            <Button onClick={() => deleteBanner()} className={classes.deleteButton} variant="contained">Удалить</Button>
        </Toolbar>
    )
}

const BannerEdit = props => {
        const classes = useStyles();
        const [services, setServices] = useState([]);
        const [stocks, setStocks] = useState([]);
        const [type, setType] = useState(null);
        const [cityKey, setCityKey] = useState(null);
        const dataProvider = useDataProvider();

        useEffect(() => {
            if (cityKey === null) {
                return;
            }

            dataProvider.getList('all_services', {filter: {city_key: cityKey}}).then(data => {
                const services = data.data.map(service => {
                    return {id: service.id, name: service.title}
                });

                setServices(services);
            });

            dataProvider.getList('stocks', {filter: {city_key: cityKey}}).then(data => {
                const stocks = data.data.map(stock => {
                    return {id: stock.id, name: stock.title}
                });

                setStocks(stocks);
            });
        }, [cityKey]);

        const ImagePreview = ({record}) => {
            const protocol = process.env.REACT_APP_BASE_URL.split(':/')[0];
            const src = record.src || `${protocol}://${record}`;

            return (
                <img src={src} alt='' className={classes.input}/>
            )
        };

        return (
            <Edit {...props} title={<Title/>}>
                <SimpleForm redirect={`/list_of_banners/${props.match.params.city_key}`}
                            toolbar={<BannerToolbar city_key={props.match.params.city_key}/>}>
                    <TextField source="id"/>
                    <BooleanInput label="Опубликован" source="is_active"/>
                    <TextInput label="Название" source="title_mp" validate={required()} className={classes.input}/>
                    <TextInput multiline rows='5' label="Описание" source="description_mp" validate={required()}
                               className={classes.input}/>
                    <ImageInput source="image_url" label="Изображение"
                                labelSingle="Перетащите изображение для загрузки или нажмите, чтобы выбрать его"
                                accept="image/*" style={{width: '300px'}} className={classes.input}
                    validate={required()}>
                        <ImagePreview source="src" style={{width: '300px'}} className={classes.input}/>
                    </ImageInput>
                    <SelectInput label="Добавить ссылку" source="type" className={classes.input}
                                 onClick={e => setType(e.target.value)} choices={[
                        {id: 'stock', name: 'Акция'},
                        {id: 'service', name: 'Услуга'},
                        {id: 'url', name: 'Внешняя ссылка'},
                        {id: 'none', name: 'Отсутствует'}
                    ]}/>

                    {type === 'service' &&
                    <SelectInput label="Услуга" source="service_id" choices={services} className={classes.input}
                                 validate={required()}/>
                    }

                    {type === 'stock' &&
                    <SelectInput label="Акция" source="stock_id" choices={stocks} className={classes.input}
                                 validate={required()}/>
                    }

                    {type === 'url' &&
                    <TextInput label="Внешняя ссылка" source="external_page_url" className={classes.input}
                               validate={required()}/>
                    }

                    <FormDataConsumer subscription={{values: true}}>
                        {({formData, ...rest}) => {
                            setType(formData.type);
                            setCityKey(formData.city_key);
                        }}
                    </FormDataConsumer>
                </SimpleForm>
            </Edit>
        );
    }
;

export default BannerEdit;