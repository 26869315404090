import React from "react"
import UserList from "./Components/UserList";
import PushForm from "./Components/PushForm";
import {useState} from "react";


const PushUserForm = props => {
    const [selectedUsers, setSelectedUsers] = useState([])
    return(
        <>
            <PushForm fileId={props.fileId} selectedUsers={selectedUsers}/>
            <UserList fileId={props.fileId} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers}/>
        </>
    )
}

export default PushUserForm