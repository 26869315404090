import React, {useState, useEffect} from 'react';
import dayjs from 'dayjs'
import {useDataProvider} from 'react-admin';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

let cities = {};

const Statistics = () => {
  const today = dayjs();
  const format = 'DD.MM.YYYY';
  const formatInstall = 'YYYYMMDD';

  const dataProvider = useDataProvider();

  const [error, setError] = useState(null);

  const [registrationChartData, setRegistrationChartData] = useState(null);
  const [installationChartData, setInstallationChartData] = useState(null);

  const [installationCount, setInstallationCount] = useState(null);

  const [registrFromDate, setRegistrFromDate] = useState((today.subtract(7, 'days')).toDate());
  const [registrToDate, setRegistrToDate] = useState(today.toDate());
//.format(format))
  const [installFromDate, setInstallFromDate] = useState((today.subtract(7, 'days')).toDate());
  const [installToDate, setInstallToDate] = useState(today.toDate());

  useEffect(() => {
    handleGetRegistrationCount();
  }, []);

  useEffect(() => {
    handleGetRegistrationStatistics(registrFromDate, registrToDate);
  }, [registrFromDate, registrToDate]);

  useEffect(() => {
    handleGetInstallationStatistics(installFromDate, installToDate);
  }, [installFromDate, installToDate]);


  const handleGetRegistrationCount = () => {
    dataProvider.getRegistrationCount('statistics').then((data) => {
      setInstallationCount({...data.data, total_registered: data.data.content.reduce(
        (count, city) => count + city.total_registered, 0)});
    }).catch(() => {
      setError(false);
    });
  };

  const getRegistrationChartPoints = (points) => points.map(point => ({
    date: point.date,
    ...point.cities.reduce((obj, cur) => {
      cities = {...cities, [cur.city_key]: {key: cur.city_key, name: cur.city_name }}
      return {...obj, [cur.city_key]: cur.total_registered}
    }, {})
  }));

  const getInstallationChartPoints = (points) => points.map(point => ({
    date: dayjs(point.date, 'DD-MM-YYYY').format(format),
    ...point.os_info.reduce((obj, cur) => {
      return {...obj, all: obj.all + cur.total_installed, [cur.os_name.toLowerCase()]: cur.total_installed}
    }, {all: 0})
  }));


  const handleGetRegistrationStatistics = (from, to) => {
    dataProvider.getStatistics(
      `statistics/registrations/period?start_date=${dayjs(from).format(format)}&end_date=${dayjs(to).format(format)}`).then((data) => {
      setRegistrationChartData(getRegistrationChartPoints(data.data));
    }).catch((e) => {
      setError(e);
    });
  };

  const handleGetInstallationStatistics = (from, to, os) => {
    dataProvider.getStatistics(
      `statistics/first_install?start_date=${dayjs(from).format(formatInstall)}&end_date=${dayjs(to).format(formatInstall)}`).then((data) => {
      setInstallationChartData(getInstallationChartPoints(data.data));
    }).catch((e) => {
      setError(e);
    });
  };

  if (!installationCount || !registrationChartData || !installationChartData) {
    return null;
  }

  return (
    <div className="statistics-container">
      <RegistrationStatistics
        chartData={registrationChartData}
        installationCount={installationCount}
        fromDate={registrFromDate}
        toDate={registrToDate}
        setToDate={setRegistrToDate}
        setFromDate={setRegistrFromDate}
      />
      <InstallationStatistics
        chartData={installationChartData}
        fromDate={installFromDate}
        toDate={installToDate}
        setToDate={setInstallToDate}
        setFromDate={setInstallFromDate}
      />
    </div>
  )
};

export default Statistics;


const RegistrationStatistics = ({chartData, installationCount, fromDate, toDate,
                                  setToDate, setFromDate}) => {
  const colors = ['#8884d8', '#82ca9d', '#dfd18b', '#df8b8b'];

  return (
    <div>
      <h4 className="statistics-header">Статистика о регистрациях по городам</h4>
      <div className="statistics-installation-count">
        <h6 className="statistics-title">Общее количество:</h6>
        <div className="statistics-install-item">
          {installationCount.content.map((city, i) => (
            <div key={i} className="statistics-city-count">{city.city_name}: {city.total_registered}</div>
          ))}
          <div className="statistics-city-count">Всего: {installationCount.total_registered}</div>
        </div>
      </div>
      <div className="statistics-datepickers-block">
        <h6 className="statistics-title">Рост количества зарегистрированных пользователей:</h6>
        <div className="statistics-datepicker-block">
          <label className="statistics-datepicker-label">От</label>
          <DatePicker
            className="statistics-datepicker"
            placeholderText="Дата начала выборки"
            dateFormat="dd.MM.yyyy"
            selected={fromDate}
            onChange={date => setFromDate(date)}
            selectsStart
            startDate={fromDate}
            endDate={toDate}
          />
        </div>
        <div className="statistics-datepicker-block">
          <label className="statistics-datepicker-label">До</label>
          <DatePicker
            className="statistics-datepicker"
            placeholderText="Дата конца выборки"
            dateFormat="dd.MM.yyyy"
            selected={toDate}
            onChange={date => setToDate(date)}
            selectsEnd
            startDate={fromDate}
            endDate={toDate}
          />
        </div>

      </div>

      {!chartData || !chartData.length ? (
        <div className="statistics-no-content">Нет данных о зарегистрированных пользователях</div>
      ) : (
        <LineChart
          width={800}
          height={500}
          data={chartData}
          margin={{
            top: 5, right: 30, left: 20, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          {Object.values(cities).map((city, i) => (
            <Line isAnimationActive={false} key={city.key} type="linear" dataKey={city.key} name={city.name} stroke={colors[i]} />))}
        </LineChart>
      )}
    </div>
  )
}


const InstallationStatistics = ({chartData, fromDate, toDate, setToDate, setFromDate}) => {
  const osOptions = [
    {value: 'all', label: 'Все'},
    {value: 'ios', label: 'IOS'},
    {value: 'android', label: 'Android'},
    {value: 'diff', label: 'IOS + Android'}
  ]
  const [selectedOS, setSelectedOS] = useState(osOptions[0].value);

  return (
    <div>
      <h4 className="statistics-header margin-top">Статистика об установках</h4>
      <div className="statistics-datepickers-block">
        <div className="statistics-datepicker-block">
          <label className="statistics-datepicker-label">ОС</label>
          <select className="statistics-datepicker" defaultValue={selectedOS} onChange={e => {
            console.log(e.target.value)
            setSelectedOS(e.target.value)
          }}>
            {osOptions.map(option => (<option key={option.value} value={option.value}>{option.label}</option>))}
          </select>
        </div>
        <div className="statistics-datepicker-block">
          <label className="statistics-datepicker-label">От</label>
          <DatePicker
            className="statistics-datepicker"
            placeholderText="Дата начала выборки"
            dateFormat="dd.MM.yyyy"
            selected={fromDate}
            onChange={date => setFromDate(date)}
            selectsStart
            startDate={fromDate}
            endDate={toDate}
          />
        </div>
        <div className="statistics-datepicker-block">
          <label className="statistics-datepicker-label">До</label>
          <DatePicker
            className="statistics-datepicker"
            placeholderText="Дата конца выборки"
            dateFormat="dd.MM.yyyy"
            selected={toDate}
            onChange={date => setToDate(date)}
            selectsEnd
            startDate={fromDate}
            endDate={toDate}
          />
        </div>
      </div>
      {!chartData || !chartData.length ? (
        <div className="statistics-no-content">Нет данных об установках</div>
      ) : (
        <LineChart
          width={800}
          height={500}
          data={chartData}
          margin={{
            top: 5, right: 30, left: 20, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          {selectedOS === osOptions[0].value && (
            <Line type="linear" isAnimationActive={false} dataKey="all" name={osOptions[0].label} stroke="#df8b8b" />)}
          {selectedOS === osOptions[1].value && (
            <Line type="linear" isAnimationActive={false} dataKey="ios" name={osOptions[1].label} stroke="#8884d8" />)}
          {selectedOS === osOptions[2].value && (
            <Line type="linear" isAnimationActive={false} dataKey="android" name={osOptions[2].label} stroke="#82ca9d" />)}
          {selectedOS === osOptions[3].value && (
            [<Line type="linear" key="ios" isAnimationActive={false} dataKey="ios" name={osOptions[1].label} stroke="#8884d8" />,
              <Line type="linear" key="android" isAnimationActive={false} dataKey="android" name={osOptions[2].label} stroke="#82ca9d" />]
          )}

        </LineChart>
      )}
    </div>
  )
}