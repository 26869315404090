import React from 'react';
import {
    List,
    useDataProvider,
    useNotify
} from 'react-admin';
import SortableComponent from "./BannerSortable";
import BannerActions from "./BannerActions";
import BannersEmpty from "./BannersEmpty";


const BannerList = props => {
    const notify = useNotify()
    const dataProvider = useDataProvider()
    return (
        <List {...props}
              pagination={null}
              exporter={false}
              title='Баннеры'
              bulkActionButtons={false}
              filter={{city_key: props.match.params.city_key}}
              actions={<BannerActions/>}
              empty={<BannersEmpty/>}
        >
            <SortableComponent city_key={props.match.params.city_key} dataProvider={dataProvider}
                               notify={notify}/>
        </List>
    )
};

export default BannerList;