import React, {useState} from "react"
import Button from "@material-ui/core/Button";
import DoctorSyncDialog from "./DoctorSyncDialog/DoctorSyncDialog";
import {useDataProvider} from "react-admin";

const Doctor1CSyncButton = ({record, props}) => {
    const [open, setOpen] = useState(false)
    const dataProvider = useDataProvider()
    return (
        <>
            <Button onClick={() => setOpen(true)} variant="contained" color="primary">
                Синхронизировать с 1С
            </Button>
            <DoctorSyncDialog {...props} dataProvider={dataProvider} open={open} setOpen={setOpen}/>
        </>
    )
}
export default Doctor1CSyncButton