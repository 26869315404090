import React, {useState} from "react"
import {
    Dialog,
    DialogTitle,
    LinearProgress,
} from "@material-ui/core";
import DoctorSyncDialogFound from "./DoctorSyncDialogFound";
import DoctorSyncDialogForm from "./DoctorSyncDialogForm";
import DoctorSyncDialogNotFound from "./DoctorSyncDialogNotFound";

const DoctorSyncDialog = props => {
    const [loading, setLoading] = useState(false)
    const [foundDoctor, setFoundDoctor] = useState(null)
    const [code1C, setCode1C] = useState("")
    const [error, setError] = useState(false)

    const {open, setOpen, dataProvider} = props
    const {city_key} = props.match.params

    const handleClose = () => {
        setOpen(false)
        setCode1C("")
        setLoading(false)
        setFoundDoctor(null)
    }
    const handleBack = () => {
        setError(false)
    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
        >
            {loading &&
            <>
                <LinearProgress/>
                <LinearProgress color="secondary"/>
            </>}
            <DialogTitle id="max-width-dialog-title">Синхронизация 1С</DialogTitle>
            {foundDoctor ? <DoctorSyncDialogFound {...props} handleClose={handleClose} dataProvider={dataProvider}
                                                  doctor={foundDoctor} code1C={code1C} city_key={city_key}/> : error ?
                <DoctorSyncDialogNotFound handleBack={handleBack}/> :
                <DoctorSyncDialogForm {...props} dataProvider={dataProvider} setOpen={setOpen}
                                      setLoading={setLoading} city_key={city_key} setFoundDoctor={setFoundDoctor}
                                      setCode1C={setCode1C} code1C={code1C} handleClose={handleClose}
                                      setError={setError} error={error}/>}
        </Dialog>
    )
}

export default DoctorSyncDialog