import * as React from 'react';
import { Edit, SimpleForm, TextField, BooleanInput } from 'react-admin';
import Toolbar from '../../Layout/Toolbar';

const Title = ({ record }) => {
  return <span>Пуш {record ? `"${record.name}"` : ''}</span>;
};

const SettingsEdit = props => (
  <Edit {...props} title={<Title />}>
    <SimpleForm redirect={false} toolbar={<Toolbar />}>
      <TextField label="Название" source="name" />
      <TextField label="Описание" source="description" />
      <BooleanInput label="Активность" source="is_active" />
    </SimpleForm>
  </Edit>
);

export default SettingsEdit;