import * as React from "react";
import {Admin, Resource, ListGuesser, fetchUtils} from 'react-admin';
import {reducer as tree} from 'ra-tree-ui-materialui';
import AuthProvider from "./authProvider";
import DataProvider from "./dataProvider";
import MainLayout from "./Layout/Layout";
import serviceViews from "./Views/Services";
import doctorViews from "./Views/Doctors";
import scheduleViews from "./Views/Schedule";
import bannerViews from "./Views/Banners";
import Dashboard from "./Layout/Dashboard";
import routes from "./routes";
import SettingsList from "./Views/Pushes/SettingsList";
import SettingsEdit from "./Views/Pushes/SettingsEdit";
import {postRowStyle} from './utils';
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import russianMessages from 'ra-language-russian';
import {indigo} from "@material-ui/core/colors";


const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
};
const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru');
const baseTheme = createMuiTheme({
    palette: {
        secondary: {
            main: indigo[900],
        }
    },
});
const App = () => (
    <MuiThemeProvider theme={baseTheme}>
        <Admin layout={MainLayout}
               dashboard={Dashboard}
               customRoutes={routes}
               authProvider={AuthProvider(process.env.REACT_APP_BASE_URL, process.env.REACT_APP_AUTH_HASHES, process.env.REACT_APP_AUTH_URL)}
               dataProvider={DataProvider(process.env.REACT_APP_BASE_URL, httpClient)}
               customReducers={{tree}}
               i18nProvider={i18nProvider}>
            <Resource name="cities" list={ListGuesser}/>
            <Resource name="doctors/:city_key" {...doctorViews} options={{myCustomAttr: "10", rowStyle: postRowStyle}}/>
            <Resource name="clinics/schedule/:city_key" {...scheduleViews}/>
            <Resource name="list_of_banners/:city_key" {...bannerViews} />
            <Resource name="all_services/:city_key" list={serviceViews.list}/>
            <Resource name="services" edit={serviceViews.edit}/>
            <Resource name="push/settings" list={SettingsList} edit={SettingsEdit}/>
            <Resource name="stocks"/>
        </Admin>
    </MuiThemeProvider>
);

export default App;
