import * as React from 'react';
import {Route} from 'react-router-dom';
import PushesByCity from './Views/Pushes/PushesByCity'
import Prices from './Views/Prices';
import Statistics from "./Views/Statistics/Statistics";
import PushFileList from "./Views/Pushes/Individual/PushFileList";
import PushUserForm from "./Views/Pushes/Individual/PushUserForm";
import PushHistory from "./Views/Pushes/Individual/PushHistory";
import PushesByCityHistory from "./Views/Pushes/PushesByCityHistory";


export default [
    <Route exact path="/pushes/:city_key"
           component={params => <PushesByCity cityKey={params.match.params.city_key}/>}/>,
    <Route exact path="/pushes/cities/history/:city_key"
           component={params => <PushesByCityHistory cityKey={params.match.params.city_key}/>}/>,
    <Route exact path="/statistics" component={params => <Statistics cityKey={params.match.params.city_key}/>}/>,
    <Route exact path="/individual-pushes" component={PushFileList}/>,
    <Route exact path="/individual-pushes/:file_id"
           component={params => <PushUserForm fileId={params.match.params.file_id}/>}/>,
    <Route exact path="/individual-pushes/:file_id/history"
           component={params => <PushHistory fileId={params.match.params.file_id}/>}/>,
    <Route exact path="/prices" component={Prices}/>,
];