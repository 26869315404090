import * as React from 'react';
import {useState} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import {Title, useDataProvider, useRedirect} from 'react-admin';
import SubmitButton from '../../Components/SubmitButton';
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme=>({
  historyButton:{
    marginTop:10
  }
}))


const PushesByCity = props => {
  const {cityKey} = props;
  const classes = useStyles()
  const dataProvider = useDataProvider();
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const redirect = useRedirect()

  const sendPush = () => {
    setIsLoading(true);

    dataProvider.sendRequest('push/city', {
      city_key: cityKey,
      title: title,
      body: body
    }).then(() => {
      setTitle('');
      setBody('');
      setIsLoading(false);
      alert('Пуш успешно отправлен');
    }).catch(() => {
      setIsLoading(false);
      alert('Не удалось отправить пуш');
    });
  };

  return (
    <Card>
      <Title title="Пуши по городам" />
      <CardContent>

        <form onSubmit={sendPush}>
          <div>
            <TextField required id="title" label="Заголовок пуша" value={title} onChange={e => setTitle(e.target.value)} />
          </div>
          <div>
            <TextField required id="body" label="Текст пуша" value={body} onChange={e => setBody(e.target.value)} />
          </div>

          <SubmitButton
            variant='contained'
            color='primary'
            isLoading={isLoading}
            text='Отправить'
          />
        </form>
        <Button className={classes.historyButton} variant={"contained"} color={"secondary"} onClick={()=>redirect(`/pushes/cities/history/${cityKey}`)}>История</Button>
      </CardContent>
    </Card>
  );


};

export default PushesByCity;