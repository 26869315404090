import * as React from 'react';
import { cloneElement} from 'react';
import {
    useListContext,
    TopToolbar,
    CreateButton,
    sanitizeListRestProps,
} from 'react-admin';

const ScheduleActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
    } = useListContext();

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={window.location.href.split("#")[1]} />
        </TopToolbar>
    );
}

export default ScheduleActions