import * as React from "react";
import { useRedirect, useNotify, useDataProvider } from 'react-admin'
import DeleteIcon from '@material-ui/icons/Delete';
import {Button, fade } from '@material-ui/core';
import red from "@material-ui/core/colors/red";
import makeStyles from "@material-ui/core/styles/makeStyles";


const useStyles = makeStyles(theme => ({
    outlinedRed: {
        color: red[500],
        border: `1px solid ${fade(red[500], 0.5)}`,
        "&:hover": {
            border: `1px solid ${red[500]}`,
            "@media (hover: none)": {
                backgroundColor: "transparent"
            }
        }
    }
}));

const deleteScheduleEvent = (record, props, dataProvider, redirect, notify) => {
    const confirmation = window.confirm(`Вы действительно хотите удалить расписание?`);

    if (confirmation) {
        dataProvider.delete('clinics/schedule', { id: record.id })
            .then(response => {
                if (response.status === 200) {
                    redirect(props.basePath.replace(":city_key", props.match.params.city_key));
                    notify('Расписание удалено');
                } else {
                    notify("Не удалось удалить расписание, повторите попытку позже", 'warning');
                }
            })
            .catch(error => {
                notify(`Возникли проблемы с удалением расписания`, 'warning');
            });
    }
};

const ScheduleDeleteButton = ({ record, props }) => {
    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const notify = useNotify();
    const classes = useStyles();

    if (record) {
            return (
                <Button
                    variant="outlined"
                    // color="secondary"
                    startIcon={<DeleteIcon />}
                    className={classes.outlinedRed}
                    onClick={() => deleteScheduleEvent(record, props, dataProvider, redirect, notify)}
                >
                    Удалить
                </Button>
            )
    }
    return null;
};

export default ScheduleDeleteButton;
