import * as React from 'react';
import {useState, useEffect} from 'react';
import {
    Create,
    SimpleForm,
    TextField,
    TextInput,
    SelectInput,
    ImageInput,
    BooleanInput,
    required,
    useDataProvider, FormDataConsumer
} from 'react-admin';
import makeStyles from "@material-ui/core/styles/makeStyles";


const useStyles = makeStyles((theme) => ({
    input: {
        width: '40%'
    }
}));

const BannerCreate = props => {
    const classes = useStyles();
    const [services, setServices] = useState([]);
    const [stocks, setStocks] = useState([]);
    const [type, setType] = useState(null);
    const [cityKey, setCityKey] = useState(props.match.params.city_key);
    const dataProvider = useDataProvider();

    useEffect(() => {
        if (cityKey === null) {
            return;
        }

        dataProvider.getList('all_services', {filter: {city_key: cityKey}}).then(data => {
            const services = data.data.map(service => {
                return {id: service.id, name: service.title}
            });

            setServices(services);
        });

        dataProvider.getList('stocks', {filter: {city_key: cityKey}}).then(data => {
            const stocks = data.data.map(stock => {
                return {id: stock.id, name: stock.title}
            });

            setStocks(stocks);
        });
    }, []);

    const ImagePreview = ({record}) => {
        const protocol = process.env.REACT_APP_BASE_URL.split(':/')[0];
        const src = record.src || `${protocol}://${record}`;

        return (
            <img src={src} alt='' className={classes.input}/>
        )
    };

    return (
        <Create {...props} title={"Создать баннер"}>
            <SimpleForm initialValues={{city_key: props.match.params.city_key}}
                        redirect={`/list_of_banners/${props.match.params.city_key}`}>
                <TextField source="id"/>
                <BooleanInput label="Активный" source="is_active"/>
                <TextInput label="Название" source="title_mp" validate={required()} className={classes.input}/>
                <TextInput multiline rows='5' label="Описание" source="description_mp" validate={required()}
                           className={classes.input}/>
                <ImageInput source="image_url" label="Изображение"
                            labelSingle="Перетащите изображение для загрузки или нажмите, чтобы выбрать его"
                            accept="image/*" style={{width: '300px'}} className={classes.input}
                >
                    <ImagePreview source="src" style={{width: '300px'}} className={classes.input}/>
                </ImageInput>
                <SelectInput label="Тип акции" source="type" className={classes.input}
                             onClick={e => setType(e.target.value)} choices={[
                    {id: 'stock', name: 'Акция'},
                    {id: 'service', name: 'Услуга'},
                    {id: 'url', name: 'Внешняя ссылка'}
                ]}/>

                {type === 'service' &&
                <SelectInput label="Услуга" source="service_id" choices={services} className={classes.input}
                             validate={required()}/>
                }

                {type === 'stock' &&
                <SelectInput label="Акция" source="stock_id" choices={stocks} className={classes.input}
                             validate={required()}/>
                }

                {type === 'url' &&
                <TextInput label="Внешняя ссылка" source="external_page_url" className={classes.input}
                           validate={required()}/>
                }
                <FormDataConsumer subscription={{values: true}}>
                    {({formData, ...rest}) => {
                        setType(formData.type);
                        setCityKey(formData.city_key);
                    }}
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    );
};

export default BannerCreate;