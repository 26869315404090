import React from "react"
import {DialogActions, DialogContent, DialogContentText} from "@material-ui/core";
import Button from "@material-ui/core/Button";

const DoctorSyncDialogNotFound = props => {
    const {handleBack} = props
    return (
        <>
            <DialogContent>
                <DialogContentText>
                    Доктор с таким кодом уже существует или не найден!
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleBack} color="primary">
                    Вернуться
                </Button>
            </DialogActions>
        </>
    )
}

export default DoctorSyncDialogNotFound