import React, {useEffect, useRef, useState} from "react"
import {useDataProvider, useNotify} from "react-admin";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dropdown} from "primereact/dropdown";
import "./style.css"

const UserList = props => {
    const dataProvider = useDataProvider()
    const {selectedUsers, setSelectedUsers, fileId} = props
    const notify = useNotify()
    const dt = useRef(null)
    const [mpStatus, setMpStatus] = useState(null);
    const [users, setUsers] = useState([])

    const statuses = [
        "Да", "Нет"
    ]
    const mpItemTemplate = (option) => {
        return <span>{option}</span>;
    }
    const onStatusChange = (e) => {
        let value = null
        if (e.value === "Да") {
            value = true
        } else if (e.value === "Нет") {
            value = false
        } else {
            value = null
        }
        dt.current.filter(value, 'is_active_in_app', 'equals');
        setMpStatus(e.value)
    }
    const mpFilter = <Dropdown value={mpStatus} options={statuses} onChange={onStatusChange}
                               itemTemplate={mpItemTemplate} placeholder="Выберите статус" className="p-column-filter"
                               showClear/>;
    useEffect(() => {
        dataProvider.getOne('push/individual', {id: fileId})
            .then(response => {
                setUsers(response.data.users)
            })
            .catch(reason => {
                notify("Ошибка загрузки списка пользователей", "warning")
            })
    }, [])

    return (
        <div className="card">
            <DataTable paginator rows={10} ref={dt} selection={selectedUsers}
                       onSelectionChange={e => setSelectedUsers(e.value)} className="p-datatable-gridlines" dataKey="id"
                       value={users}>
                <Column selectionMode="multiple" headerStyle={{width: '3em'}}/>
                <Column key="id" field="last_name" filter header="Фамилия" filterMatchMode="contains"
                        filterPlaceholder="Поиск по фамилии"/>
                <Column key="id" field="first_name" filter header="Имя" filterMatchMode="contains"
                        filterPlaceholder="Поиск по имени"/>
                <Column key="id" field="patronymic_name" filter header="Отчество" filterMatchMode="contains"
                        filterPlaceholder="Поиск по отчеству"/>
                <Column columnKey="id" field="phone" filter header="Телефон" filterMatchMode="contains"
                        filterPlaceholder="Поиск по телефону"/>
                <Column key="id" field="is_active_in_app" filter filterElement={mpFilter}
                        body={rowData => rowData.is_active_in_app ?
                            "Да" : "Нет"} header="Пользователь мобильного приложения" filterPlaceholder="Поиск по статусу МП"/>
                <Column key="id" field="city_name" filter header="Город" filterMatchMode="contains"
                        filterPlaceholder="Поиск по городу"/>
            </DataTable>
        </div>

    )
}

export default UserList